<template>
  <v-container>
    <v-card class="mx-auto col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <v-card-title class="d-flex justify-space-around">
        <h1 class="font-weight-medium text-center" id="disabled_idle">
          Ajuste de tamaño y páginas - Adjuntos
        </h1>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            class="d-flex flex-wrap align-center justify-center"
            cols="6"
            xs="6"
            sm="6"
            md="6"
          >
            <v-combobox 
              v-model="select"
              :items="items" 
              :search-input.sync="search"
              label="Compañías"
              chips
              multiple
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  label
                  small
                >
                  <span class="pr-2">
                    {{ item.text }}
                  </span>
                  <v-icon
                    small
                    @click="parent.selectItem(item)"
                  >
                    $delete
                  </v-icon>                
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex flex-wrap align-center justify-center"
            cols="12"
            xs="12"
            sm="12"
            md="12"
          >
            <v-btn
              class="light-blue darken-1 white--text txt-center"
              @click="get_companies"
            >
              Iniciar proceso
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ModalForm
      :visible="loader.estado"
      @close="loader.estado = false"
      :message="loader.message"
    ></ModalForm>
  </v-container>
</template>

<script>
import ModalForm from "../modal/ModalForm.vue";
export default {
  components: {
    ModalForm,
  },
  data() {
    return {
      loader: {
        estado: false,
        message: "",
      },
      companies: [],
      select: [],
      items: [],
      search: null,
    };
  },
  watch: {
    async search(val) {
      if (val == null || String(val)?.length < 3) {
        this.items = [];
        return;
      }

      let uri = "/cmps/search-items";
      let params = {
        search: val          
      };
      this.axios.post(uri, params)
      .then(response => {
        //console.log(JSON.stringify(response.data))
        this.items = response.data.map(i => {
          return {
            text: i.cmp_nmbre + " (" + i.cmp_nmro + ")",
            value: i._id
          }
        });
      });
    },    
  },
  methods: {
    async get_companies() {
      try {
        this.loader.estado = true;
        this.loader.message =
          "Por favor espere mientras se consultan los datos..";

        if (this.select.length == 0) {
          this.loader.estado = false;
          return alert("Seleccione las compañias");
        }

        const params = {
          search: this.select.map((e) => e.value)
        };
        const companies = await this.axios.get("/api/all-cmp", { params });

        if (!companies.data.success)
          return alert("Error en la respuesta de las compañias");

        this.companies = companies.data.data;
        this.init_fix();
      } catch (error) {
        this.loader.estado = false;
        console.log("[Error en la consulta de las compañias]: ", error);
        alert("Error en la consulta de las compañias");
      }
    },

    async init_fix() {
      try {
        for await (const item of this.companies) {
          this.loader.message = `Procesando compañia ${item.cmp_nmbre}`;

          let limit = 100;
          let total = Math.ceil(item.count / limit);

          let iterator = 0;
          const array = new Array(total).fill("").map((e) => (e = iterator++));

          for await (const number of array) {
            await this.run_adjuncts({
              ...item,
              number: number + 1,
              total,
              limit,
              skip: number * limit,
            });
          }

         await this.delay(3000);
        }

        this.loader.estado = false;
        await this.delay(100);
        alert("Proceso terminado.");
      } catch (error) {
        alert("Error corrigiendo adjuntos");
        console.log("[Error fix adjuncts: ]", error);
        this.loader.estado = false;
      }
    },

    async run_adjuncts({ _id: cmp_id, cmp_nmbre, skip, limit, number, total } = {}) {
      try {
        let uri = `/api/fix-adjuncts`;
        let send_data = { cmp_id, skip, limit };

        this.loader.message = `Procesando compañia ${cmp_nmbre}. Documento ${number * limit} de ${total * limit}`;

        await this.axios.post(uri, { ...send_data });
        this.setClick();
      } catch (error) {
        console.log("[Error run adjuncts: ]", error);
      }
    },

    setClick() {
      let element = document.querySelector("body");
      element.dispatchEvent(new KeyboardEvent("keydown", { key: "a" }));
    },

    delay(millisec) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve("");
        }, millisec);
      });
    },
  },
};
</script>